import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

const Img = styled.img`
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
`

const BannerBeStudentComponent = () => (
  <Link legacyBehavior passHref href="/">
    <a>
      <Img
        src="https://cordasemusica.com.br/wp-content/themes/cordas-e-musica/images/banner-seja-um-aluno-do-cordas-e-musica.jpg"
        alt="Seja um aluno do cordas e Música"
      />
    </a>
  </Link>
)

export const BannerBeStudent = React.memo(BannerBeStudentComponent)
