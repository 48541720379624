import styled, { CSSProp } from 'styled-components'
import { Field } from 'formik'
import theme from 'src/theme/theme'
import MaskedInput from 'react-text-mask'

export interface defaultProps {
  border: string
  color: string
  background: string
}

export interface InputProps {
  hasCssRedirect: boolean
  css: CSSProp | defaultProps
  focus: boolean
  placeholdercolor: string
}

export interface InputPropsPreset {
  preset: InputProps
}

export const Input = styled(Field)<InputPropsPreset>`
  border-radius: 0.5rem;
  border: none;
  padding: 1rem 1rem;
  margin-top: 0.3rem;
  width: 100%;
  outline: 0;
  position: relative;
  resize: vertical;
  transition: all 0.2s ease 0s;

  ${p =>
    p.preset.hasCssRedirect
      ? { ...p.preset.css }
      : `
      background-color: ${p.preset.css.background};
      color: ${p.preset.css.color};
      border: ${p.preset.css.border} !important;
    `}

  &:disabled {
    background-color: var(--gray-900);
    color: ${p => p.preset.placeholdercolor};
  }

  &:disabled::placeholder {
    color: ${p => p.preset.placeholdercolor};
  }

  &::placeholder {
    color: ${p => p.preset.placeholdercolor};
  }

  ${({ preset }) =>
    preset.focus &&
    `
      &:focus {
        border: 2px solid ${theme.colors.palette.primary500};
      }
    `}
`

export const StyledMaskedInput = styled(MaskedInput)<InputPropsPreset>`
  border-radius: 0.5rem;
  border: none;
  padding: 1rem 1rem;
  margin-top: 0.3rem;
  width: 100%;
  outline: 0;
  position: relative;
  resize: vertical;
  transition: all 0.2s ease 0s;

  ${p =>
    p.preset.hasCssRedirect
      ? { ...p.preset.css }
      : `
      background-color: ${p.preset.css.background};
      color: ${p.preset.css.color};
      border: ${p.preset.css.border} !important;
    `}

  &:disabled {
    background-color: var(--gray-900);
    color: ${p => p.preset.placeholdercolor};
  }

  &:disabled::placeholder {
    color: ${p => p.preset.placeholdercolor};
  }

  &::placeholder {
    color: ${p => p.preset.placeholdercolor};
  }

  ${({ preset }) =>
    preset.focus &&
    `
      &:focus {
        border: 2px solid ${theme.colors.palette.primary500};
      }
    `}
`
