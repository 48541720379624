import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { reset } from 'src/store/errors'
import { State } from 'src/store/rootReducers'

export function useErrors() {
  const error = useSelector((state: State) => state.errors.error)
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      toast(error, { type: 'error' })
      dispatch(reset())
    }
  }, [error])
}
