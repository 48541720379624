import {
  Formik,
  Form as DefaultForm,
  FormikConfig,
  FormikProvider,
  FormikContextType,
  FormikProps,
} from 'formik'
import React from 'react'

interface Params<T> extends FormikConfig<FormikProps<T>> {
  className?: string
  children: React.ReactNode
}

interface ParamsProvider<T> {
  className?: string
  provider: FormikContextType<T>
  children?: React.ReactNode
}

export function FormProvider<T>(props: ParamsProvider<T>) {
  const { className, provider } = props

  return (
    <FormikProvider value={provider}>
      <DefaultForm className={` ${className ?? ''}`}>{props.children}</DefaultForm>
    </FormikProvider>
  )
}

export default function Form<T>(props: Params<T>) {
  const { className, ...rest } = props

  return (
    <Formik {...rest}>
      <DefaultForm className={` ${className ?? ''}`}>{props.children}</DefaultForm>
    </Formik>
  )
}
