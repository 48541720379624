import { TxKeyPath } from 'src/i18n'
// React
import React, { HTMLInputTypeAttribute } from 'react'
// Formik
import { useField } from 'formik'
// Components
import Input from 'src/components/Common/Input'
// Styles
import { InputProps } from 'src/components/Common/Input/styles'
import theme from 'src/theme/theme'
import { Container, Error } from './styles'

export type InputPresets = keyof typeof presets

interface Params {
  name: string
  label?: TxKeyPath | string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  styleLabel?: React.CSSProperties
  disabled?: boolean
  required?: boolean
  maxLength?: number
  mask?: string | Array<string | RegExp>
  tooltip?: string
  component: string
  preset?: InputPresets
  options?: { name: string | undefined; value: string | undefined }[]
  cols?: string
  rows?: string
}

function Field(props: Params) {
  const [field, meta] = useField(props.name)
  const {
    name,
    label,
    placeholder,
    type = 'text',
    disabled = false,
    required = false,
    mask = '',
    styleLabel,
    component,
    options,
    cols,
    rows,
  } = props
  const presetStyles = presets[props?.preset || 'primary']
  const inputProps = {
    name,
    cols,
    rows,
    component,
    presetStyles,
    placeholder,
    mask,
    type,
    disabled,
  }

  return (
    <Container aria-required={required} className="input-container">
      {props.label && (
        <label htmlFor={name} style={styleLabel}>
          <span>{label}</span>
        </label>
      )}
      <Input {...field} options={options} {...inputProps} />
      {meta && meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Container>
  )
}

interface PresetsInput {
  [key: string]: InputProps
}

const presets: PresetsInput = {
  primary: {
    focus: false,
    hasCssRedirect: false,
    css: {
      border: `1px solid ${theme.colors.palette.neutral450} `,
      color: theme.colors.primaryTextColor,
      background: theme.colors.white,
    },
    placeholdercolor: theme.colors.secondaryTextColor,
  },
  comment: {
    focus: true,
    hasCssRedirect: true,
    css: {
      border: `1px solid ${theme.colors.palette.neutral450} `,
      color: theme.colors.primaryTextColor,
      background: theme.colors.white,
    },
    placeholdercolor: theme.colors.secondaryTextColor,
  },
  commentChildren: {
    focus: true,
    hasCssRedirect: true,
    css: {
      border: `1px solid ${theme.colors.palette.neutral450} `,
      color: theme.colors.primaryTextColor,
      background: theme.colors.palette.neutral200,
    },
    placeholdercolor: theme.colors.secondaryTextColor,
  },
}

export default Field
