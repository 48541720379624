import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 0.8rem;
  position: relative;

  label {
    color: ${({ theme }) => theme.colors.primaryTextColor}};
    ${({ theme }) => ({...theme.size.xs})}

    & span {
      position: relative;
      display: inline-block;
      ${({ theme }) => ({...theme.typography.primary.medium})}
    }
  }

  & + & {
    margin: 1rem 0;
  }

  &[aria-required='true'] label span::after {
    content: '*';
    position: absolute;
    right: -10px;
    top: 0;
    color: ${({ theme }) => theme.colors.palette.red500};
  }
`;

export const Error = styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.palette.red500};
  ${({ theme }) => ({...theme.size.xxs})}
`;

