// React
import React, { HTMLInputTypeAttribute } from 'react'
// Styles
import * as Styled from './styles'
import { InputProps } from './styles'

interface Params {
  name: string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  maxLength?: number
  mask?: string | Array<string | RegExp>
  tooltip?: string
  component: string
  value?: string | string[]
  required?: boolean
  options?: { name: string | undefined; value: string | undefined }[]
  multiple?: boolean
  presetStyles?: InputProps
  cols?: string
  rows?: string
}

function Input(props: Params) {
  const {
    name,
    placeholder,
    type = 'text',
    disabled = false,
    mask = '',
    component,
    presetStyles,
    cols,
    rows,
  } = props
  const inputProps = { name, component, placeholder, mask, type, disabled, cols, rows }

  if (props.component === 'select') {
    return (
      <>
        <Styled.Input {...inputProps} preset={presetStyles}>
          <option value="">---</option>
          {props.options?.map((item, index) => (
            <option key={`item.name-${index}`} value={item.value}>
              {item.name}
            </option>
          ))}
        </Styled.Input>
      </>
    )
  }

  if (props?.mask) {
    return (
      <Styled.Input {...inputProps} preset={presetStyles}>
        {({ field }) => <Styled.StyledMaskedInput preset={presetStyles} {...field} mask={mask} />}
      </Styled.Input>
    )
  }

  return (
    <>
      <Styled.Input {...inputProps} preset={presetStyles} />
    </>
  )
}

export default Input
